import advertiseBackground from "assets/images/advertise-background.jpg";
import advertiseChar from "assets/images/advertise-char.png";
import advertiseTextImg from "assets/images/advertise-text-img.png";
import { CONTACT_EMAIL } from "configs";
import "./Advertise.scss";
import { Slide } from "react-awesome-reveal";

const Advertise = () => {
  const handleContact = async () => {
    const subject = encodeURIComponent("INTERESTED IN ADVERTISING");
    const body = encodeURIComponent(
      "Kindly introduce yourself, your brand and what kind of advertising you are interested in.\nWe will get back to you as soon as possible."
    );

    window.location.href = `mailto:${CONTACT_EMAIL}?subject=${subject}&body=${body}`;
  };

  return (
    <div className="advertise" id="advertise">
      <img src={advertiseBackground} alt="" className="background" />

      <div className="screens">
        <div className="screen-inner">
          <div className="text-section">
            <div>
              To advertise on MITA contact us and we will be happy to discuss
              more in details.
            </div>
            <div onClick={handleContact}>Contact us</div>
          </div>

          <img src={advertiseTextImg} alt="" className="text-img" />
          <Slide direction="up" triggerOnce className="char-anim">
            <img src={advertiseChar} alt="" className="char" />
          </Slide>
        </div>
      </div>

      <div className="header">
        <div className="highlighted">
          GROW
          <br />
          YOUR BRAND
        </div>
        <div className="title">Connect with Gen Z</div>
        <div className="message">
          Boost & amplify your brand's visibility through MITA's advertising
          solutions. From splash screens to spot promo ads and strategic banner
          placements, we ensure your message resonates in the perfect spot.
        </div>
      </div>
    </div>
  );
};

export default Advertise;
