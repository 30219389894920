import "./TermsPage.scss";
import { useEffect, useState } from "react";
import CommunityGuidelines from "./CommunityGuidelines";
import ContactUs from "./ContactUs";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
import termsOfUseBackground from "assets/images/terms-page-background.png";
import { useLocation } from "react-router-dom";
import { scrollToElement } from "utils/helpers";

const sections = [
  { title: "Community Guidelines", path: "/community-guidelines" },
  { title: "Terms of Use", path: "/terms-of-use" },
  { title: "Privacy Policy", path: "/privacy-policy" },
  { title: "Contact us", path: "/contact-us" },
];

const sectionComponents = {
  "/community-guidelines": CommunityGuidelines,
  "/terms-of-use": TermsOfUse,
  "/privacy-policy": PrivacyPolicy,
  "/contact-us": ContactUs,
};

const TermsPage = ({ path }) => {
  const [activePath, setActivePath] = useState(path || sections[0].path);

  let location = useLocation();

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  useEffect(() => {
    scrollToElement("nav-header");
  }, []);

  const SectionComponent = sectionComponents[activePath];

  return (
    <div className="terms">
      <img className="terms-background" src={termsOfUseBackground} alt="" />

      <div className="sections-holder">
        <div className="sections">
          {sections.map((s, i) => (
            <div
              key={i}
              onClick={() => setActivePath(s.path)}
              className={`${activePath === s.path ? "active" : ""}`}
            >
              {s.title}
            </div>
          ))}
        </div>

        <SectionComponent />
      </div>
    </div>
  );
};

export default TermsPage;
