import UploadMusicBackground from "assets/images/upload-music-background.png";
import rechargeOfflineCharacter from "assets/images/recharge-offline-character.png";
import rechargeOnlineCharacter from "assets/images/recharge-online-character.png";
import { useAuthContext } from "context/authContext";
import { useDocumentTitle, useMetaDescription } from "utils/hooks";
import "./UploadMusic.scss";

const UploadMusic = () => {
  const { userData } = useAuthContext();

  useDocumentTitle("Upload Music To MITA");
  useMetaDescription("");

  return (
    <div className="upload-music">
      <img
        className="upload-music-background"
        src={UploadMusicBackground}
        alt=""
      />

      <img src={rechargeOnlineCharacter} alt="" className="character-image" />

      <div className="recharge-display-info"></div>
    </div>
  );
};

export default UploadMusic;
