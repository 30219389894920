import Advertise from "./Advertise";
import Explore from "./Explore";
import Home from "./Home";
import P2P from "./P2P";
import Recharge from "./Recharge";
import TermsPage from "./TermsPage";
import ThankYou from "./ThankYou";
import UploadMusic from "./UploadMusic";

export const pages = [
  { path: "/", element: <Home /> },
  { path: "/explore", element: <Explore /> },
  { path: "/recharge", element: <Recharge /> },
  { path: "/advertise", element: <Advertise /> },
  { path: "/p2p", element: <P2P /> },
  {
    path: "/community-guidelines",
    element: <TermsPage path="/community-guidelines" />,
  },
  { path: "/terms-of-use", element: <TermsPage path="/terms-of-use" /> },
  { path: "/privacy-policy", element: <TermsPage path="/privacy-policy" /> },
  { path: "/contact-us", element: <TermsPage path="/contact-us" /> },
  { path: "/thank-you", element: <ThankYou /> },
  { path: "/upload-music", element: <UploadMusic /> },
];
