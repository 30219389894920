import { Modal } from "react-bootstrap";

const ComingSoonModal = ({ showModal, handleClose, message }) => {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
      contentClassName="voucherModal"
    >
      <div>{message}</div>
    </Modal>
  );
};

export default ComingSoonModal;
