import exploreBackground from "assets/images/explore-background.jpg";
import exploreHeaderText from "assets/images/explore-header-text.png";
import { useEffect, useLayoutEffect, useState } from "react";
import { useComponentInView } from "utils/hooks";
import { explorePageContents } from "../utils";
import "./Explore.scss";
import ExploreCard from "./ExploreCard";
import SectionIndicator from "./SectionIndicator";

const Explore = ({ setActiveSection }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [cardsContainerWidth, setCardsContainerWidth] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const [cardsContainerRef, isVisible] = useComponentInView();

  useLayoutEffect(() => {
    setCardsContainerWidth(cardsContainerRef.current.clientWidth);

    const handleResize = () => setIsMobile(window.innerWidth < 590);

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollForward = () => {
    if (activeIndex === explorePageContents.length - 1) return;
    setActiveIndex(activeIndex + 1);
  };

  const scrollBackward = () => {
    if (activeIndex === 0) return;
    setActiveIndex(activeIndex - 1);
  };

  const scrollToIndex = (index) => {
    if (index < 0 || index > explorePageContents.length - 1) return;
    if (index === activeIndex) return;
    setActiveIndex(index);
  };

  useEffect(() => {
    if (isVisible) {
      setActiveSection("explore");
    }
  }, [isVisible]);

  return (
    <div className="explore" id="explore">
      <img src={exploreBackground} alt="" className="background" />

      <div className="header-text-img-container">
        <img src={exploreHeaderText} alt="" className="header-text-img" />
      </div>

      <div className="explore-cards-container" ref={cardsContainerRef}>
        {explorePageContents.map((content, index) => (
          <ExploreCard
            containerWidth={cardsContainerWidth}
            key={index}
            index={index}
            {...content}
            isMobile={isMobile}
            isActive={index === activeIndex}
            setActiveIndex={setActiveIndex}
            scrollForward={scrollForward}
            scrollBackward={scrollBackward}
          />
        ))}
      </div>

      <SectionIndicator
        totalSections={explorePageContents.length}
        currentIndex={activeIndex}
        scrollForward={scrollForward}
        scrollBackward={scrollBackward}
        scrollToIndex={scrollToIndex}
      />
    </div>
  );
};

export default Explore;
