import { useAuthContext } from "context/authContext";
import { useState } from "react";
import { Modal } from "react-bootstrap";

const LoginModal = ({ showModal, handleClose }) => {
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { signIn } = useAuthContext();

  const handleSubmitLogin = async () => {
    setErrorMessage(null);
    setIsLoading(true);

    const { error } = await signIn(username);
    if (error) setErrorMessage(error);
    else handleClose();

    setIsLoading(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
      contentClassName="loginModal"
    >
      <div>Sign in</div>

      <div>
        <input
          placeholder="Enter your username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        {errorMessage && <div className="text-danger mb-3">{errorMessage}</div>}

        <div
          onClick={handleSubmitLogin}
          style={{ borderRadius: "1.5rem", marginTop: "1.5rem" }}
        >
          {!isLoading ? "Submit" : "..."}
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
