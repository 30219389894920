import mitaCoins from "assets/images/mita-coins.png";
import mitaSignupSheet from "assets/images/mita-signup-sheet.png";
import p2pBackground from "assets/images/p2p-background.png";
import { useEffect } from "react";
import { scrollToElement } from "utils/helpers";
import { useDocumentTitle, useMetaDescription } from "utils/hooks";
import "./P2P.scss";
import MerchantCard from "./MerchantCard";
import joyImage from "assets/images/joy.jpg";
import godsloveImage from "assets/images/godslove.png";
import nicholasImage from "assets/images/nicholas.jpg";

const merchants = [
  {
    name: "Nicholas",
    country: "Ghana",
    image: nicholasImage,
    link: "https://api.whatsapp.com/send?phone=233545024150&text=Hello%2C%20I%20am%20MITA%20Agent%20how%20can%20I%20help%20you%3F",
  },
  {
    name: "Godslove",
    country: "Ghana",
    image: godsloveImage,
    link: "https://api.whatsapp.com/send?phone=233546255931&text=Hello%2C%20I%20am%20MITA%20Agent%20how%20can%20I%20help%20you%3F",
  },
  {
    name: "Joy",
    country: "Nigeria",
    image: joyImage,
    link: "https://api.whatsapp.com/send?phone=2347011649133&text=Hello%2C%20I%20am%20MITA%20Agent%20how%20can%20I%20help%20you%3F",
  },
];

const P2P = () => {
  useDocumentTitle(
    "P2P Transactions Made Simple with MITA - Empowering Your Digital Economy"
  );

  useMetaDescription(
    "Become a p2p merchant on MITA today and explore the ease and efficiency of peer-to-peer transactions in the Metaverse with MITA. Unlock the power of a seamless digital economy and experience the future of financial interaction. Join MITA and redefine your P2P experience while creating a new income stream."
  );

  const openApplication = () =>
    window.open(
      "https://forms.gle/oVewqJ7WoJ1ZQmkP8",
      "_blank",
      "noopener,noreferrer"
    );

  useEffect(() => {
    scrollToElement("nav-header");
  }, []);

  return (
    <div className="p2p" id="p2p">
      <img className="p2p-background" src={p2pBackground} alt="" />

      <div className="merchant-section">
        <div>Contact a Merchant</div>

        <div>
          {merchants.map((merchant, index) => (
            <MerchantCard key={index} {...merchant} />
          ))}
        </div>
      </div>

      <div className="p2p-inner">
        <div>
          <div>
            <div className="title">Become a</div>
            <div className="highlighted">P2P MERCHANT</div>
            <div className="message">
              Transform the way you do business by joining the MITA P2P
              merchant.  As a P2P Merchant, you'll enjoy exclusive benefits,
              including verified MITA P2P badges, special access and PROMOTION,
              fast MITA customer support, and more.
            </div>
          </div>
        </div>

        <div>
          <div>
            <div>
              <img src={mitaSignupSheet} alt="" />
            </div>
            <div>
              <div className="title">Business Merchant</div>
              <div className="message">
                Provide liquidity as a P2P business entity on the MITA
                marketplace. As a business P2P, you'll enjoy more benefits and
                revenue generation by providing fiat currency and other payment
                Solutions.
              </div>
              <div className="btn" onClick={openApplication}>
                Apply
              </div>
            </div>
          </div>

          <div>
            <div>
              <img src={mitaCoins} alt="" />
            </div>
            <div>
              <div className="title">Agents</div>
              <div className="message">
                Empower your local community and become a MITA agent, earn with
                MITA! Offer the MITA P2P services on our platform in your local
                area and get rewarded. It's time to embrace the local call, it
                pays off after all.
              </div>
              <div className="btn" onClick={openApplication}>
                Apply
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default P2P;
