import amateurIcon from "assets/icons/ranks/amateur.png";
import challengerIcon from "assets/icons/ranks/challanger.png";
import legendIcon from "assets/icons/ranks/legend.png";
import mitaMasterIcon from "assets/icons/ranks/mita-master.png";
import noviceIcon from "assets/icons/ranks/novice.png";
import vip1 from "assets/icons/vip-levels/vip1.png";
import vip2 from "assets/icons/vip-levels/vip2.png";
import vip3 from "assets/icons/vip-levels/vip3.png";
import vip4 from "assets/icons/vip-levels/vip4.png";
import vip5 from "assets/icons/vip-levels/vip5.png";
import vip6 from "assets/icons/vip-levels/vip6.png";
import vip7 from "assets/icons/vip-levels/vip7.png";
import vip8 from "assets/icons/vip-levels/vip8.png";
import discount10Icon from "assets/icons/vip-privileges/10-discount.png";
import discount30Icon from "assets/icons/vip-privileges/30-discount.png";
import customizationIcon from "assets/icons/vip-privileges/customization.png";
import doubleExpIcon from "assets/icons/vip-privileges/double-exp.png";
import doublePointsIcon from "assets/icons/vip-privileges/double-points.png";
import rewards15Icon from "assets/icons/vip-privileges/rewards-15.png";
import vipBadgeIcon from "assets/icons/vip-privileges/vip-badge.png";
import vipRewardsIcon from "assets/icons/vip-privileges/vip-rewards.png";
import withdrawDiscount10 from "assets/icons/vip-privileges/withdraw-discount-10.png";
import withdrawMoreIcon from "assets/icons/vip-privileges/withdraw-more.png";
import withdrawlPlus1 from "assets/icons/vip-privileges/withdrawal-plus-1.png";
import exploreMitaImg from "assets/images/explore-mita-1.png";
import exploreMitaMobileImg from "assets/images/explore-mita-1-mobile.png";
import playGamesImg from "assets/images/explore-mita-2.png";
import playGamesMobileImg from "assets/images/explore-mita-2-mobile.png";
import hangoutWithFriendsImg from "assets/images/explore-mita-3.png";
import hangoutWithFriendsMobileImg from "assets/images/explore-mita-3-mobile.png";
import earnRewardImg from "assets/images/explore-mita-4.png";
import earnRewardMobileImg from "assets/images/explore-mita-4-mobile.png";
import createAvatarImg from "assets/images/explore-mita-5.png";
import createAvatarMobileImg from "assets/images/explore-mita-5-mobile.png";
import voicePartyImg from "assets/images/explore-mita-6.png";
import voicePartyMobileImg from "assets/images/explore-mita-6-mobile.png";

export const explorePageContents = [
  {
    id: "explore-mita",
    title: "Explore MITA",
    image: exploreMitaImg,
    mobileImage: exploreMitaMobileImg,
    text: `Discover a fusion of a Social Metaverse and a Multiplayer Gaming Revolution. MITA isn't just an app; it's a COMMUNITY.
    
    With MITA, seamlessly bridge your digital experiences to your real-world adventures. Start exploring MITA now - where every Moment becomes an EXPRESSION!`,
  },
  {
    id: "play-games",
    title: "Play Games",
    image: playGamesImg,
    mobileImage: playGamesMobileImg,
    text: `Level Up Your Gaming! Enter into a healthy competition of endless multiplayer gaming and adventures on MITA. 

    Are you into thrilling races, strategic battles, or casual games, we've got something for everyone. Play, challenge, and earn rewards as you immerse in a world of excitement and fun.`,
  },

  {
    id: "hangout-with-friends",
    title: "Hangout With Friends",
    image: hangoutWithFriendsImg,
    mobileImage: hangoutWithFriendsMobileImg,
    text: `Connect and hangout with your friends and forge new relationships. MITA offers a unique way to socialize and engage with your friends. 

    Whether you want to chat, join a family, or compete in challenges, MITA has everything you need to stay connected and engaged. Start exploring the endless possibilities on the MITAverse.`,
  },
  {
    id: "earn-rewards",
    title: "Earn Reward",
    image: earnRewardImg,
    mobileImage: earnRewardMobileImg,
    text: `With those that did shared tales about the MITA rewards and incentives. With MITA, you are rewarded every step of the way as long as you are engaging.
    
    We can't emphasize enough: claim your daily rewards. Loyalty truly has its perks!`,
  },
  {
    id: "create-avatar",
    title: "Create Your Avatar",
    image: createAvatarImg,
    mobileImage: createAvatarMobileImg,
    text: `Craft Your Digital Persona! Your unique identity into the MITA universe (MITAverse).

    Customize your MITA Avatar to reflect your style, mood, and personality. From outfits to accessories, design the avatar that stands out in the MITA community, design you, become your avatar!`,
  },
  {
    id: "voice-party",
    title: "Voice Party",
    image: voicePartyImg,
    mobileImage: voicePartyMobileImg,
    text: `Craft Your Digital Persona! Your unique identity into the MITA universe (MITAverse).

    Customize your MITA Avatar to reflect your style, mood, and personality. From outfits to accessories, design the avatar that stands out in the MITA community, design you, become your avatar!`,
  },
];

export const vipPrivileges = [
  {
    icon: vipBadgeIcon,
    title: "VIP Badge",
    description: "Eye-catching VIP Badge",
    info: null,
  },
  {
    icon: withdrawMoreIcon,
    title: "Withdraw More",
    description: "25% withdrawal limit increase",
    info: null,
  },
  {
    icon: vipRewardsIcon,
    title: "Login Bonus",
    description: "Diamonds every day",
    info: null,
  },
  {
    icon: customizationIcon,
    title: "Customization",
    description: "Multiple Avatar color customization",
    info: null,
  },
  {
    icon: rewards15Icon,
    title: "15% Rewards",
    description: "Get more game rewards",
    info: null,
  },
  {
    icon: doubleExpIcon,
    title: "Double EXP",
    description: "Fast VIP upgrade",
    info: "Unlock at VIP 2",
  },
  {
    icon: doublePointsIcon,
    title: "Double Points",
    description: "2x your Rank levels faster",
    info: "Unlock at VIP 3",
  },
  {
    icon: withdrawDiscount10,
    title: "10% Discount",
    description: "Discount on Withdrawal Fees",
    info: "Unlock at VIP 5",
  },
  {
    icon: discount30Icon,
    title: "10% Discount",
    description: "Buy shop items for less price",
    info: "Unlock at VIP 7",
  },
  {
    icon: discount10Icon,
    title: "30% Discount",
    description: "Get Discount on P2P Fees",
    info: "Unlock at VIP 8",
  },
  {
    icon: withdrawlPlus1,
    title: "+1 Withdrawal",
    description: "1 extra increase in withdrawal limit",
    info: "Unlock at VIP 8",
  },
];

export const defaultAccountInfo = {
  avatar: "https://avatars.dicebear.com/api/bottts/api/bottts/ktester.png",
  avatarData: "",
  fullName: "",
  username: "",
  level: 0,
  memberType: "",
  vipLevel: 1,
  diamondBalance: 0,
  mitaBalance: 0,
};

export const vipIcons = {
  1: vip1,
  2: vip2,
  3: vip3,
  4: vip4,
  5: vip5,
  6: vip6,
  7: vip7,
  8: vip8,
};

export const rankIcons = {
  novice: noviceIcon,
  amateur: amateurIcon,
  legend: legendIcon,
  challenger: challengerIcon,
  mitaMaster: mitaMasterIcon,
};

export const adTypes = [
  { img: null, title: "App load splash screen" },
  { img: null, title: "In-game advertisement" },
  { img: null, title: "Mood update advertisement" },
  { img: null, title: "Home page avartar advertisement" },
];
