import { mitaWebApiUrl } from "configs";
import { createContext, useContext, useState } from "react";
import { getRequest } from "utils/requests";

const AuthContext = createContext({
  userData: null,
  signIn: async (username) => {},
  signOut: () => {},
});

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  const signIn = async (__username) => {
    let error = null;

    if (!__username && userData.username) {
      __username = userData.username;
    }

    if (!__username || __username.length < 4) {
      if (userData.username) __username = userData.username;
      else error = "Please enter a valid username";
    }

    if (!error) {
      const responseData = await getRequest(
        `${mitaWebApiUrl}/profile?username=${__username}`
      );

      console.log({ loginResponse: responseData });

      if (responseData.hasError) error = responseData.message;
      else setUserData(responseData.data);
    }

    return { error };
  };

  const signOut = () => {
    setUserData(null);
  };

  return (
    <AuthContext.Provider
      value={{
        userData,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuthContext };
