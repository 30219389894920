import hamburgerIcon from "assets/icons/hamburger-icon.png";
import mitaLogo from "assets/images/mita-logo-color-horizontal.png";
import { useLayoutEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavHeader.scss";
import SignInButton from "./SignInButton";

const navLinks = [
  { id: "explore", title: "Explore", path: "/?heading=explore" },
  { id: "line" },
  { id: "tutorial", title: "Tutorials", path: "/?heading=tutorial" },
  { id: "line" },
  { id: "advertise", title: "Advertise", path: "/?heading=advertise" },
  { id: "line" },
  { id: "p2p", title: "Merchants", path: "/p2p" },
  { id: "line" },
  { id: "recharge", title: "Recharge", path: "/recharge"},
];

const NavHeader = ({
  toggleLoginModal,
  setNavHeaderHeight,
  activeNavSection = null,
}) => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  const location = useLocation();
  const pathname = location.pathname;

  const navHeaderRef = useRef(null);

  useLayoutEffect(() => {
    const navHeaderHeight = navHeaderRef.current.offsetHeight;
    setNavHeaderHeight(navHeaderHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isOtherDesign = ["/recharge", "/upload-music"].includes(pathname);

  return (
    <div className="nav-header" id="nav-header" ref={navHeaderRef}>
      <div className="nav-header-inner">
        <div>
          <Link to="/">
            <img src={mitaLogo} alt="MITA Logo" />
          </Link>

          <div>
            <div
              className="desktop-display"
              style={{
                justifyContent: !isOtherDesign ? "flex-end" : "flex-start",
              }}
            >
              {navLinks
                .filter((link) =>
                  isOtherDesign
                    ? link.path === "/recharge" || link.path === "/upload-music"
                    : true
                )
                .map((link, i) => {
                  const isActiveSection = activeNavSection === link.id;
                  return link.id !== "line" ? (
                    <Link
                      key={i}
                      to={link.path}
                      className={`${
                        pathname === link.path || isActiveSection
                          ? "active"
                          : ""
                      }`}
                    >
                      {link.title}
                    </Link>
                  ) : (
                    <div className="line" key={i} />
                  );
                })}

              <SignInButton onClick={toggleLoginModal} />
            </div>

            <div className="mobile-display">
              <div>
                <SignInButton onClick={toggleLoginModal} />
                <div>
                  <img
                    src={hamburgerIcon}
                    alt="Mobile Menu Button"
                    onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
                    // onTouchStart={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
                    style={{ height: "2rem" }}
                  />
                </div>
              </div>

              <div
                className={`mobile-menu ${
                  mobileMenuIsOpen ? "mobile-menu-open" : ""
                }`}
              >
                {navLinks.map((link, i) => (
                  <Link
                    key={i}
                    to={link.path}
                    onClick={() => setMobileMenuIsOpen(false)}
                    className={`${pathname === link.path ? "active" : ""}`}
                  >
                    {link.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
