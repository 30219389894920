import ComingSoonModal from "components/ComingSoonModal";
import ErrorModal from "components/ErrorModal";
import Footer from "components/Footer";
import LoginModal from "components/LoginModal";
import NavHeader from "components/NavHeader";
import { useState } from "react";
import { Outlet } from "react-router-dom";

const App = () => {
  const [comingSoonMessage, setComingSoonMessage] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [navHeaderHeight, setNavHeaderHeight] = useState(0);
  const [activeNavSection, setActiveNavSection] = useState(null);

  const setAppErrorMessage = (message) => setErrorMessage(message);
  const toggleLoginModal = () => setShowLoginModal((prev) => !prev);

  return (
    <div className="App">
      <div className="body">
        <NavHeader
          toggleLoginModal={toggleLoginModal}
          setNavHeaderHeight={setNavHeaderHeight}
          activeNavSection={activeNavSection}
        />

        <Outlet
          context={[
            setComingSoonMessage,
            setAppErrorMessage,
            navHeaderHeight,
            setActiveNavSection,
          ]}
        />
      </div>

      <Footer setComingSoonMessage={setComingSoonMessage} />

      <ErrorModal
        showModal={Boolean(errorMessage)}
        handleClose={() => setErrorMessage(null)}
        message={errorMessage}
      />

      <ComingSoonModal
        showModal={!!comingSoonMessage}
        handleClose={() => setComingSoonMessage(null)}
        message={comingSoonMessage}
      />

      <LoginModal
        showModal={showLoginModal}
        handleClose={() => setShowLoginModal(false)}
      />
    </div>
  );
};

export default App;
