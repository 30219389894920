import { useAuthContext } from "context/authContext";
import Payment from "./Payment";
import Privileges from "./Privileges";
import { useState } from "react";
import SuccessSubscribeModal from "components/SuccessSubscribeModal";

const AccountInfo = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { userData } = useAuthContext();

  return (
    <div className="loggedIn">
      <Privileges />

      <SuccessSubscribeModal
        showModal={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
      />

      {userData && (
        <Payment
          isVip={userData?.vipLevel > 0}
          handleShowSuccessModal={() => setShowSuccessModal(true)}
        />
      )}
    </div>
  );
};

export default AccountInfo;
