import { useNavigate } from "react-router-dom";
import "./ThankYou.scss";
import thankYouImg from "assets/images/thank-you.png";

const ThankYou = () => {
  const navigate = useNavigate();
  return (
    <div className="thank-you">
      <div>
        <img src={thankYouImg} alt="" />
        <div>
          <div>Your upgrade was successful{"\n"}Welcome to the VIP family!</div>
          <div onClick={() => navigate("/")}>Done</div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
