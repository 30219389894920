import rightExploreButton from "assets/icons/right-explore-button.png";
import leftExploreButton from "assets/icons/left-explore-button.png";

const ExploreButton = ({ direction = "right" || "left" }) => {
  return (
    <img
      src={direction === "right" ? rightExploreButton : leftExploreButton}
      alt=""
      style={{
        width: "4rem",
        height: "4.5rem",
      }}
    />
  );
};

export default ExploreButton;
