import { useAuthContext } from "context/authContext";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const SignInButton = ({ onClick }) => {
  const [currentLocation, setCurrentLocation] = useState(null);

  const { userData, signOut } = useAuthContext();

  let location = useLocation();

  useEffect(() => {
    setCurrentLocation(location);
  }, [location]);

  const defaultColor =
    "linear-gradient(91deg, #24C765 54.16%, #EFFB63 115.17%)";

  const pathColorMap = {
    "/recharge": "#754CE6",
    "/upload-music": "#754CE6",
    "/": defaultColor,
  };

  return (
    <div
      style={{
        background: !userData
          ? pathColorMap[(currentLocation ?? location).pathname] ?? defaultColor
          : "#F15BB5",
      }}
      className="sign-in-button"
      onClick={!userData ? onClick : signOut}
    >
      {!userData ? "Sign in" : "Sign out"}
    </div>
  );
};

export default SignInButton;
