const MerchantCard = ({ name, country, image, link }) => {
  const handleChat = () => {
    window.open(link, "_blank");
  };

  return (
    <div className="merchant-card">
      <div>
        <img src={image} alt="" />
      </div>

      <div>
        <div>
          <div>{name}&nbsp;</div>
          <div>&#40;{country}&#41;</div>
        </div>

        <div onClick={handleChat}>Chat on WhatsApp</div>
      </div>
    </div>
  );
};

export default MerchantCard;
