import { useLayoutEffect, useRef } from "react";
import { Modal } from "react-bootstrap";

const VideoPlayer = ({ videoUrl, handleClose }) => {
  const iframeRef = useRef(null);

  useLayoutEffect(() => {
    const iframe = iframeRef.current;

    if (iframe) {
      iframe.style.width = "100%";
      iframe.style.height = "100%";
    }
  }, []);

  return (
    <Modal
      show={true}
      onHide={handleClose}
      centered
      contentClassName="playerModal"
      dialogClassName="playerModalDialog"
      className="playerModalWrapper"
    >
      <div
        style={{
          height: "100%",
        }}
      >
        <iframe
          title="video"
          id="video"
          ref={iframeRef}
          src={videoUrl}
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </Modal>
  );
};

export default VideoPlayer;
