import diamondIcon from "assets/icons/diamond.png";
import levelIcon from "assets/icons/level-icon.png";
import mitaCoinIcon from "assets/icons/mita-coin.png";
import { useAuthContext } from "context/authContext";
import { defaultAccountInfo, rankIcons, vipIcons } from "pages/utils";
import { convertToMoney, trimMoney, upperCaseFirstLetter } from "utils/helpers";

const UserInfo = () => {
  const { userData } = useAuthContext();
  const accountLoggedIn = userData ?? defaultAccountInfo;

  return (
    <div className="user-info">
      <div className="top">
        <div>
          <div>
            {upperCaseFirstLetter(
              (accountLoggedIn.fullName?.length > 0
                ? accountLoggedIn.fullName
                : accountLoggedIn.username) ?? ""
            )}
          </div>
          <div>@{accountLoggedIn.username}</div>
        </div>

        <div className="user-traits">
          {accountLoggedIn.vipLevel > 0 && (
            <div>
              <img
                src={vipIcons[accountLoggedIn.vipLevel]}
                alt=""
                style={{ width: "4rem", height: "1.7rem" }}
              />
            </div>
          )}

          <div>
            <img
              src={levelIcon}
              className="me-1"
              alt=""
              style={{ width: "1.5rem" }}
            />{" "}
            Lv {accountLoggedIn.level}
          </div>

          <div>
            {accountLoggedIn.memberType !== "free" && (
              <img
                src={rankIcons[accountLoggedIn.memberType]}
                alt=""
                className="me-1"
                style={{ width: "1.5rem" }}
              />
            )}
            {upperCaseFirstLetter(accountLoggedIn.memberType)}{" "}
            {accountLoggedIn.memberType === "free" && "Member"}
          </div>
        </div>
      </div>

      <div className="user-balance">
        <div>Your amount</div>

        <div>
          <div>
            <img
              src={diamondIcon}
              alt=""
              className="me-1"
              style={{ width: "1.5rem" }}
            />
            {trimMoney(convertToMoney(accountLoggedIn.diamondBalance))}
          </div>
          <div>
            <img
              src={mitaCoinIcon}
              alt=""
              className="me-1"
              style={{ width: "1.5rem" }}
            />
            {trimMoney(convertToMoney(accountLoggedIn.mitaBalance))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
