import upArrowBlackIcon from "assets/icons/up-arrow-black-icon.png";
import downArrowBlackIcon from "assets/icons/down-arrow-black-icon.png";

import { useState } from "react";

const ListInfoItem = ({ index, title, body }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="listed-info">
      <div className="title">
        <div>
          {index + 1}. {title}
        </div>
        <img
          src={isExpanded ? upArrowBlackIcon : downArrowBlackIcon}
          alt=""
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </div>
      {isExpanded && (
        <div className="body" style={{ whiteSpace: "pre-wrap" }}>
          {body}
        </div>
      )}
    </div>
  );
};

export default ListInfoItem;
