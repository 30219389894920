import tutorialBackground from "assets/images/tutorial-background.jpg";
import tutorialVrImage from "assets/images/tutorial-vr-image.png";
import "./Tutorial.scss";
import playButton from "assets/icons/play-button.png";
import demoVideoDefaultImage from "assets/images/demo-video-default-image.png";
import { Slide } from "react-awesome-reveal";
import demoVideoThumbnail1 from "assets/images/demo-video-thumbnail-1.jpg";
import demoVideoThumbnail2 from "assets/images/demo-video-thumbnail-2.jpg";
import demoVideoThumbnail3 from "assets/images/demo-video-thumbnail-3.jpg";

const videos = [
  {
    title: "How to customize your avatar on MITA",
    description: "",
    videoUrl: "https://www.youtube.com/embed/-KiwAgM1AgQ?si=Vz3iclUhFITibeLX",
    img: demoVideoThumbnail1,
  },
  {
    title: "How to add friends on MITA",
    description: "",
    videoUrl: "https://www.youtube.com/embed/j4E4k4xLk3k?si=Gz3CAGLxGz0mk2e2",
    img: demoVideoThumbnail2,
  },
  {
    title: "How to complete Quest on MITA",
    description: "",
    videoUrl: "https://www.youtube.com/embed/eyEfo9XTwOg?si=t8VjkzKDbDrYDC2D",
    img: demoVideoThumbnail3,
  },
];

const mainVideo =
  "https://www.youtube.com/embed/E7zHS-Yaf4o?si=2lUdGAUaDgpcA9N-";

const Tutorial = ({ setVideoToPlay }) => {
  return (
    <div className="tutorial" id="tutorial">
      <img src={tutorialBackground} alt="" className="background" />

      <img
        src={tutorialVrImage}
        alt=""
        className="vr-image"
        onClick={() => setVideoToPlay(mainVideo)}
      />

      <Slide direction="left" triggerOnce className="char-anim" duration={2000}>
        <div className="demo-videos">
          {videos.map((video, i) => (
            <div key={i} className="video-card">
              <div onClick={() => setVideoToPlay(video.videoUrl)}>
                <div>
                  <img src={video.img} alt="" />
                </div>
                {/* <div></div> */}
                <img src={playButton} alt="" />
              </div>
              <div>
                <div>{video.title}</div>
                <div>{video.description}</div>
              </div>
            </div>
          ))}
        </div>
      </Slide>
      <a
        href="https://www.youtube.com/playlist?list=PLZ1SM6dDLP4qZcZ6PQi8_sJkQlg9Z823B"
        target="_blank"
        rel="noreferrer"
      >
        View more &nbsp;&nbsp;➤
      </a>
    </div>
  );
};

export default Tutorial;
