import { useDocumentTitle, useMetaDescription } from "utils/hooks";

const guidelines = `Join our growing ecosystem at MITA, a digital community where users can play games, connect, share their interests and get rewarded with MITA / Diamonds for engaging with the app. MITA can be exchanged for cash, used to
purchase items from our partner stores and restaurants or exchanged among friends. You can also use MITA to purchase items from the MITA Mall, which offers a range of gadgets, fashion, and lifestyle products.

We aim to be the global digital community of choice while ensuring personal expression, views, and information are valued. To maintain the integrity of our community, we have created Community Standards that apply to
everyone and to all types of content.

As we strive to become the global digital community of choice, we are committed to promoting fun, personal expression, views, experience, ideas, and information. However, we also recognize our responsibility to ensure that our
community remains a safe and abuse-free environment. Our Community Standards apply to all users, all over the world, and to all types of content. We understand that words, images, and expressions can have different meanings
and effects on people depending on their cultural background, beliefs, and language. Therefore, anyone can report potentially violating content and comments to us for investigation and action. The MITA app has a spamming and
reporting feature for such actions.

We have various consequences for violating our Community Standards, depending on the severity of the offense. For the first violation, we will delete the offending image,video or content and issue a warning to the user via email or
chat. However, if a user continues to violate our policies, we may escalate to deleting their account. We may also take legal action if we believe there is a genuine risk of physical harm, a violation of the law, or a direct threat to public
safety.

Our Community Standards serve as a guide for what is and is not allowed in our MITA Digital Community, and we expect all users and partners to abide by them.
Our platform allows users to control their posts and actions under various categories, including fraud, deception, and misrepresentation; privacy violations, image privacy rights, and intellectual property; spam; violence and
incitement; terrorism and dangerous individuals/organizations; coordinating harm and publicizing crime; trading/selling regulated and prohibited goods; suicide and self-injury; sexual exploitation/violence and solicitation of adults
and children; bullying, harassment, cruelty, and insensitivity; human exploitation and trafficking; and hate speech. We prohibit any content that violates these categories as they go against our vision of creating a safe and fun
environment for our users.

MITA Community Guidelines also cover various aspects of inappropriate behavior and content that is not allowed on the platform. These guidelines aim to create a safe and respectful community where users can interact and
share content without fear of exploitation or harm. The guidelines prohibit fraud, deception, and misrepresentation, including the use of fake accounts or manipulative behaviors to gain an unfair advantage. They also prohibit privacy violations, such as sharing personal or
confidential information without consent or attempting to collect sensitive user data. A common example is trying to create short comments or descriptions with no meaning or context to the subject matter. Violating this will get your account banned with no warnings. 

Spam is also not allowed on the platform, as it can be misleading and negatively impact the user experience. The guidelines also prohibit violence, incitement, terrorism, and dangerous individuals or organisations, and they prohibit
coordinating harm or promoting criminal activities.

The trading or selling of regulated and prohibited goods, as well as suicide and self-injury, are also not allowed on the platform. Sexual exploitation and violence, bullying, harassment, cruelty, and insensitivity, and human
exploitation and trafficking are prohibited as well.
The guidelines also prohibit hate speech and violent or graphic content, as well as nudity and sexual activity, which can be sensitive topics that may negatively impact the community's well-being.

You can always report issues to us directly via the email address hello@mita.games`;

const CommunityGuidelines = () => {
  useDocumentTitle(
    "Community Guidelines - Building a Positive Metaverse Community"
  );

  useMetaDescription(
    "At MITA, we prioritize a positive and respectful Metaverse experience. Explore our community guidelines to understand the principles that shape our digital community. Join us in creating a welcoming and vibrant online space."
  );

  return (
    <div className="community-guideline" style={{ whiteSpace: "pre-wrap" }}>
      {guidelines}
    </div>
  );
};

export default CommunityGuidelines;
