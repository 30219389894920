import { useEffect, useRef } from "react";

export const convertToMoney = (val = "") => {
  if (typeof val === "string") val = Number(val);
  return val.toLocaleString("en-US", { maximumFractionDigits: 5 });
};

export const trimMoney = (stringWithCommas, fraction = 2) => {
  // Convert number to b,m,k,
  let number = Number(stringWithCommas.replace(/,/g, ""));
  if (isNaN(number)) return numberWithCommas;
  number = number.toFixed(fraction);

  if (number >= 1000000000000) {
    return numberWithCommas((number / 1000000000000).toFixed(fraction)) + "T";
  }
  if (number >= 1000000000) {
    return numberWithCommas((number / 1000000000).toFixed(fraction)) + "B";
  }
  if (number >= 1000000) {
    return numberWithCommas((number / 1000000).toFixed(fraction)) + "M";
  }
  if (number >= 1000) {
    return numberWithCommas((number / 1000).toFixed(fraction)) + "K";
  }
  return numberWithCommas(number);
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const upperCaseFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const supportedCountryCodeMap = {
  NG: { name: "nigeria", currency: "₦" },
  GH: { name: "ghana", currency: "GH₵" },
  KE: { name: "kenya", currency: "KSh" },
  RW: { name: "rwanda", currency: "FRw" },
  ET: { name: "ethiopia", currency: "Br" },
  ZA: { name: "south Africa", currency: "ZAR" },
  EG: { name: "egypt", currency: "EGP" },
  PH: { name: "philippines", currency: "₱" },
  PK: { name: "pakistan", currency: "₨" },
  VN: { name: "vietnam", currency: "₫" },
  IN: { name: "india", currency: "₹" },
  ID: { name: "indonesia", currency: "Rp" },
  US: { name: "usa", currency: "$" },
};

export const scrollToElement = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
