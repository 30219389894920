import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { pages } from "./pages";
import { AuthProvider } from "context/authContext";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.warn = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<App />}>
          {pages.map((page, i) =>
            !page.subroutes ? (
              <Route key={i} path={page.path} element={page.element} />
            ) : (
              <Route key={i} path={page.path}>
                {page.subroutes.map((subroute, j) => (
                  <Route
                    key={j}
                    // eslint-disable-next-line no-extra-boolean-cast
                    index={!Boolean(subroute.path)}
                    path={subroute.path ?? null}
                    element={subroute.element ?? null}
                  />
                ))}
              </Route>
            )
          )}
        </Route>
      </Routes>
    </AuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
