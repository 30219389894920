import xLogo from "assets/icons/xLogo.png";
import telegramLogo from "assets/icons/telegramLogo.png";
import emailLogo from "assets/icons/emailLogo.png";

import { CONTACT_EMAIL, TELEGRAM_URL, TWITTER_URL } from "configs";
import { useDocumentTitle, useMetaDescription } from "utils/hooks";

const socialMedia = [
  { url: TWITTER_URL, icon: xLogo },
  { url: TELEGRAM_URL, icon: telegramLogo },
  { url: `mailto:${CONTACT_EMAIL}`, icon: emailLogo },
];

const ContactUs = () => {
  useDocumentTitle(
    "Contact MITA - Connect with Us for MITA Inquiries and Support"
  );

  useMetaDescription(
    "Have questions or need support in the MITAverse? Contact MITA for assistance, inquiries, or partnership opportunities. We're here to help you navigate the digital realm with ease."
  );

  return (
    <div className="contact-us">
      <div>
        {socialMedia.map((s, i) => (
          <a href={s.url} target="_blank" rel="noreferrer">
            <img src={s.icon} alt="social media icon" />
          </a>
        ))}
      </div>
      <div>
        You can contacts us on our socials or via email hello@mita.games
      </div>
    </div>
  );
};

export default ContactUs;
