import { vipPrivileges } from "pages/utils";

const Privileges = () => {
  return (
    <div className="privileges">
      <div>
        <div>VIP Privilege</div>
        <div>The higher your VIP EXP is, the more privileges you will get</div>
      </div>

      <div>
        {vipPrivileges.map((privilege, i) => (
          <div
            key={i}
            style={privilege.info ? { justifyContent: "space-between" } : {}}
            className="privilege-card"
          >
            <img src={privilege.icon} alt="" />
            <div className="title">{privilege.title}</div>
            <div className="description">{privilege.description}</div>
            {privilege.info && (
              <div className="info">
                <span>{privilege.info}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Privileges;
