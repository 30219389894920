import RadioButton from "components/RadioButton";
import { IPINFO_TOKEN, mitaWebApiUrl } from "configs";
import { useAuthContext } from "context/authContext";
import { useEffect, useState } from "react";
import { convertToMoney, supportedCountryCodeMap } from "utils/helpers";
import { getRequest, postRequest } from "utils/requests";

const Payment = ({ isVip, handleShowSuccessModal }) => {
  const [pricing, setPricing] = useState(null);
  const [clientCountry, setClientCountry] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  let [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { userData, signIn } = useAuthContext();

  useEffect(() => {
    (async () => {
      const responseData = await getRequest(
        `https://ipinfo.io?token=${IPINFO_TOKEN}`,
        true
      );
      const detectedCountry = supportedCountryCodeMap[responseData.country];
      if (detectedCountry) setClientCountry(detectedCountry);
      else setClientCountry(supportedCountryCodeMap["US"]);
    })();
  }, []);

  useEffect(() => {
    if (!clientCountry) return;
    (async () => {
      const responseData = await getRequest(`${mitaWebApiUrl}/vip/pricing`);
      console.log({ responseData });
      setPricing(responseData.data);
    })();
  }, [clientCountry]);

  const handleSubmitCode = async () => {
    if (errorMessage) setErrorMessage(null);
    if (isLoading) return;
    if (!couponCode)
      return setErrorMessage("Please enter a valid voucher code");

    setIsLoading(true);

    const responseData = await postRequest(
      `${mitaWebApiUrl}/vip/buy-voucher?code=${couponCode}`,
      { username: userData.username, voucherCode: couponCode }
    );

    if (responseData.hasError) {
      setIsLoading(false);
      return setErrorMessage(responseData.message);
    }

    handleShowSuccessModal();
    setTimeout(() => {
      signIn();
      setIsLoading(false);
    }, 1500);
  };

  const threeMonthsTag = `${clientCountry?.name}ThreeMonthsPrice`;
  const oneYearTag = `${clientCountry?.name}OneYearPrice`;

  const threeMonthsPrice = pricing?.[threeMonthsTag] || 0;
  const oneYearPrice = pricing?.[oneYearTag] || 0;

  return (
    <div className="payment">
      <div>
        <div>Choose payment plan</div>

        <div>
          <div onClick={() => setSelectedTag(threeMonthsTag)}>
            <div>
              <div>3 Months</div>
              <div>
                {clientCountry?.currency}
                {convertToMoney(threeMonthsPrice)} per 3 months
              </div>
            </div>

            <RadioButton isSelected={selectedTag === threeMonthsTag} />
          </div>

          <div onClick={() => setSelectedTag(oneYearTag)}>
            <div>
              <div>1 Year</div>
              <div>
                {clientCountry?.currency}
                {convertToMoney(oneYearPrice)} per year (16% off)
              </div>
            </div>
            <RadioButton isSelected={selectedTag === oneYearTag} />
          </div>
        </div>
      </div>

      <div className="code-section">
        {!isVip && (
          <div className="code-entry">
            <label for="voucherCode">Voucher Code:</label>
            <input
              id="voucherCode"
              placeholder="Enter your voucher code"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
          </div>
        )}

        <div
          className="action-button"
          onClick={!isVip ? handleSubmitCode : () => null}
          style={isVip ? { backgroundColor: "grey", flex: 1 } : {}}
        >
          {isLoading ? "..." : !isVip ? "Subscribe" : "Already VIP Member"}
        </div>
      </div>

      {errorMessage && (
        <div
          className="text-danger text-center"
          style={{ marginBottom: "1.5rem", marginTop: "-1rem" }}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default Payment;
