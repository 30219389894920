import { ENV, SYS_KEY } from "configs";
import CryptoJS from "crypto-js";

const isProduction = ENV === "production";

const encrypt = (plainText, credentails = `${SYS_KEY}`) => {
  let encrypted = "";
  try {
    encrypted = CryptoJS.AES.encrypt(plainText, credentails).toString();
  } catch (err) {
    console.log(err);
  }
  return encrypted;
};

const decrypt = (encryptedText, credentails = `${SYS_KEY}`) => {
  let plainText = "";
  try {
    plainText = CryptoJS.AES.decrypt(encryptedText, credentails);
    plainText = plainText.toString(CryptoJS.enc.Utf8);
  } catch (err) {
    console.log(err);
  }

  return plainText;
};

export const getRequest = async (url, isOutsideMita) => {
  let headers = {};
  if (!isOutsideMita) headers = { ismitaweb: "true" };

  const response = await fetch(url, {
    method: "GET",
    headers,
  });

  let responseData;

  if (isProduction && !isOutsideMita) {
    let encryptedResponse = await response.text();
    let decryptedResponse = decrypt(encryptedResponse);

    responseData = JSON.parse(decryptedResponse);
  } else responseData = await response.json();

  return responseData;
};

export const postRequest = async (url, data) => {
  let headers = { "Content-Type": "application/json", ismitaweb: "true" };

  let body = isProduction
    ? JSON.stringify({ encryptedData: encrypt(JSON.stringify(data)) })
    : JSON.stringify(data);

  const response = await fetch(url, {
    method: "POST",
    headers,
    body,
  });

  let responseData;

  if (isProduction) {
    let encryptedResponse = await response.text();
    responseData = JSON.parse(decrypt(encryptedResponse));
  } else responseData = await response.json();

  return responseData;
};
