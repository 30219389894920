/* eslint-disable react-hooks/exhaustive-deps */
import downloadOnAppStore from "assets/images/download-on-appstore.png";
import downloadOnPlayStore from "assets/images/download-on-playstore.png";
import homeBackgroundOrnaments from "assets/images/home-background-ornaments.png";
import homeBackground from "assets/images/home-background.jpg";
import homeChar1 from "assets/images/home-char-1.png";
import homeChar2 from "assets/images/home-char-2.png";
import homeChar3 from "assets/images/home-char-3.png";
import homeChar4 from "assets/images/home-char-4.png";
import { playstoreUrl } from "configs";
import { useEffect, useState } from "react";
import { Bounce, Slide } from "react-awesome-reveal";
import {
  useLocation,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { scrollToElement } from "utils/helpers";
import Advertise from "./Advertise";
import Explore from "./Explore";
import "./Home.scss";
import Tutorial from "./Tutorial";
import VideoPlayer from "components/VideoPlayer";
import { useComponentInView } from "utils/hooks";

const Home = () => {
  const [videoToPlay, setVideoToPlay] = useState(null);

  const [setComingSoonMessage, , navHeaderHeight, setActiveNavSection] =
    useOutletContext();
  const [searchParams] = useSearchParams();
  let location = useLocation();

  const [homeRef, homeIsVisible] = useComponentInView();

  const navigateToHeaderFromUrl = () => {
    const heading = searchParams.get("heading");
    if (heading) scrollToElement(heading);
  };

  useEffect(() => {
    navigateToHeaderFromUrl();

    return () => {
      setActiveNavSection(null);
    };
  }, []);

  useEffect(() => {
    navigateToHeaderFromUrl();
  }, [location]);

  useEffect(() => {
    if (homeIsVisible) setActiveNavSection(null);
  }, [homeIsVisible]);

  return (
    <>
      <div
        className="home"
        id="home"
        style={{ marginBottom: `-${navHeaderHeight}px` }}
        ref={homeRef}
      >
        <img
          className="home-background-img first"
          src={homeBackground}
          alt=""
        />
        <img
          className="home-background-img first"
          src={homeBackgroundOrnaments}
          alt=""
        />

        <div className="chars">
          <Slide
            direction="right"
            duration={500}
            triggerOnce
            className="app-demo-image-anim"
          >
            <img
              className="home-background-img app-demo-image"
              src={homeChar1}
              alt=""
            />
          </Slide>
          <Slide
            direction="right"
            className="app-demo-image-anim"
            duration={500}
            triggerOnce
          >
            <img
              className="home-background-img app-demo-image"
              src={homeChar2}
              alt=""
            />
          </Slide>
          <Bounce className="app-demo-image-anim" triggerOnce>
            <img
              className="home-background-img app-demo-image"
              src={homeChar3}
              alt=""
            />
          </Bounce>
          <Bounce className="app-demo-image-anim" triggerOnce>
            <img
              className="home-background-img app-demo-image"
              src={homeChar4}
              alt=""
            />
          </Bounce>
        </div>

        <div className="home-relative">
          <div>
            <div>
              <div>
                MITA is the new
                <br />
                Social
              </div>
              <div>
                Explore, share moments, host parties, play games, make instant payments and earn rewards.
              </div>
            </div>

            <div>
              <a href={playstoreUrl} target="_blank" rel="noreferrer">
                <img src={downloadOnPlayStore} alt="" />
              </a>
              {/* <a href={appstoreUrl} target="_blank" rel="noreferrer" > */}
              <div
                onClick={() =>
                  setComingSoonMessage("Coming soon to Apple Store")
                }
              >
                <img src={downloadOnAppStore} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Explore setActiveSection={setActiveNavSection} />

      <Tutorial
        setVideoToPlay={setVideoToPlay}
        setActiveSection={setActiveNavSection}
      />

      <Advertise setActiveSection={setActiveNavSection} />

      {videoToPlay && (
        <VideoPlayer
          videoUrl={videoToPlay}
          handleClose={() => setVideoToPlay(null)}
        />
      )}
    </>
  );
};

export default Home;
