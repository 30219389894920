const RadioButton = ({ isSelected }) => {
  return (
    <div
      style={{
        borderRadius: "100%",
        backgroundColor: "transparent",
        border: `2px solid ${isSelected ? "#754CE6" : "#787A80"}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "1.2rem",
        height: "1.2rem",
      }}
    >
      <div
        style={{
          width: "0.7rem",
          height: "0.7rem",

          ...(isSelected
            ? { backgroundColor: "#754CE6", borderRadius: "100%" }
            : {}),
        }}
      ></div>
    </div>
  );
};

export default RadioButton;
