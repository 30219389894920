import ExploreButton from "./ExploreButton";

const SectionIndicator = ({
  totalSections,
  currentIndex,
  scrollForward,
  scrollBackward,
  scrollToIndex,
}) => {
  return (
    <div className="section-indicator">
      <div onClick={scrollBackward} className="mobile-button">
        <ExploreButton direction="left" />
      </div>

      <div className="dots">
        {Array.from({ length: totalSections }).map((_, index) => (
          <div
            key={index}
            className={`section-dot ${currentIndex === index && "active"}`}
            onClick={() => scrollToIndex(index)}
          />
        ))}
      </div>

      <div onClick={scrollForward} className="mobile-button">
        <ExploreButton direction="right" />
      </div>
    </div>
  );
};

export default SectionIndicator;
