export const ENV = process.env.REACT_APP_ENV;
export const mitaApiUrl = process.env.REACT_APP_MITA_API_URL;
export const mitaWebApiUrl = process.env.REACT_APP_MITA_WEB_API_URL;
export const playstoreUrl = process.env.REACT_APP_PLAYSTORE_URL;
export const appstoreUrl = process.env.REACT_APP_APPSTORE_URL;
export const SYS_KEY = process.env.REACT_APP_SYS_KEY;
export const CONTACT_EMAIL = process.env.REACT_APP_CONTACT_EMAIL;
export const TWITTER_URL = process.env.REACT_APP_TWITTER_URL;
export const TELEGRAM_URL = process.env.REACT_APP_TELEGRAM_URL;
export const IPINFO_TOKEN = process.env.REACT_APP_IPINFO_TOKEN;
