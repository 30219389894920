import { Modal } from "react-bootstrap";

const ErrorModal = ({ showModal, handleClose, message }) => {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
      contentClassName="voucherModal"
    >
      <div style={{ color: "red" }}>{message}</div>
    </Modal>
  );
};

export default ErrorModal;
