import exploreCardRightOrnament from "assets/images/explore-card-right-ornament.png";
import { explorePageContents } from "pages/utils";
import { useEffect, useRef } from "react";
import ExploreButton from "./ExploreButton";

const ExploreCard = ({
  id,
  index,
  title,
  image,
  mobileImage,
  text,
  isActive,
  scrollForward,
  scrollBackward,
  isMobile,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (isActive) {
      const element = ref.current;
      const container = element.parentElement;
      const middle =
        element.offsetLeft -
        container.offsetWidth / 2 +
        element.offsetWidth / 2;
      container.scrollTo({
        left: middle,
        behavior: "smooth",
      });
    }
  }, [isActive]);

  const isFirst = index === 0;
  const isLast = index === explorePageContents.length - 1;

  return (
    <div
      ref={ref}
      className={`explore-card-container ${isActive && "active"}`}
      id={id}
      style={
        isFirst
          ? {
              paddingLeft: "20vw",
            }
          : isLast
          ? {
              paddingRight: "20vw",
            }
          : {}
      }
    >
      {isActive && !isFirst && (
        <div onClick={scrollBackward} className="desktop-button">
          <ExploreButton direction="left" />
        </div>
      )}
      <div className={`explore-card`}>
        <div>
          <img src={!isMobile ? image : mobileImage} alt="" />
        </div>

        <div className="content">
          <div>{title}</div>
          <div>{text}</div>
        </div>

        <img src={exploreCardRightOrnament} alt="" className="right-ornament" />
      </div>

      {isActive && !isLast && (
        <div onClick={scrollForward} className="desktop-button">
          <ExploreButton direction="right" />
        </div>
      )}
    </div>
  );
};

export default ExploreCard;
