import { useAuthContext } from "context/authContext";
import { useDocumentTitle, useMetaDescription } from "utils/hooks";
import AccountInfo from "./AccountInfo";
import "./Recharge.scss";
import rechargeBackground from "assets/images/recharge-background.png";
import rechargeOfflineCharacter from "assets/images/recharge-offline-character.png";
import rechargeOnlineCharacter from "assets/images/recharge-online-character.png";
import UserInfo from "./UserInfo";
import { useEffect } from "react";
import { scrollToElement } from "utils/helpers";
import { Slide } from "react-awesome-reveal";

const Recharge = () => {
  const { userData } = useAuthContext();

  useDocumentTitle(
    "Recharge To MITA VIP Plan - Elevate Your Digital Lifestyle"
  );
  useMetaDescription(
    "Do you want to upgrade to MITA VIP? Use our recharge vouchers to upgrade. Buy from one of our trusted merchants/resellers."
  );

  useEffect(() => {
    scrollToElement("nav-header");
  }, []);

  return (
    <div className="recharge">
      <img className="recharge-background" src={rechargeBackground} alt="" />

      <div className="recharge-display-info">
        <div className="recharge-character">
          <Slide
            direction={!userData ? "left" : "up"}
            triggerOnce
            style={{ zIndex: -1, position: "relative" }}
          >
            <img
              src={
                !userData ? rechargeOfflineCharacter : rechargeOnlineCharacter
              }
              alt=""
            />
          </Slide>

          {userData && <UserInfo />}
        </div>

        <AccountInfo />
      </div>
    </div>
  );
};

export default Recharge;
