import { useEffect, useRef, useState } from "react";

export const useDocumentTitle = (
  title,
  defaultTitle = "MITA - Dress up your avatar, live, voice party, and play games with your friends in the MITAverse."
) => {
  useEffect(() => {
    document.title = title;
    return () => {
      document.title = defaultTitle;
    };
  }, [title, defaultTitle]);
};

export const useMetaDescription = (description) => {
  useEffect(() => {
    const metaDescription = document.querySelector('meta[name="description"]');
    const defaultDescription =
      "MITA is a new social app for making friends in the Metaverse. A space where you can honestly share your mood, play games voice party, and live with your friends.";

    metaDescription.setAttribute("content", description);

    return () => {
      metaDescription.setAttribute("content", defaultDescription);
    };
  }, [description]);
};

export const useComponentInView = (threshold = 0.5) => {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: threshold,
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [threshold]);

  return [componentRef, isVisible];
};
