import { Modal } from "react-bootstrap";
import "../pages/ThankYou.scss";
import thankYouImg from "assets/images/thank-you.png";
import { useAuthContext } from "context/authContext";

const SuccessSubscribeModal = ({ showModal, handleClose }) => {
  const { signIn } = useAuthContext();

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
      contentClassName="voucherModal success"
    >
      <div className="thank-you">
        <div>
          <img src={thankYouImg} alt="" />
          <div>
            <div>
              Your upgrade was successful{"\n"}Welcome to the VIP family!
            </div>
            <div
              onClick={async () => {
                await signIn();
                handleClose();
              }}
            >
              Done
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessSubscribeModal;
