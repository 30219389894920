import mailIcon from "assets/icons/mail-icon.png";
import telegramIcon from "assets/icons/telegram-icon.png";
import twitterIcon from "assets/icons/twitter-icon.png";
import downloadOnAppStore from "assets/images/download-on-appstore.png";
import downloadOnPlayStore from "assets/images/download-on-playstore.png";
import mitaLogoWhiteHorizontal from "assets/images/mita-logo-white-horizontal.png";
import {
  CONTACT_EMAIL,
  TELEGRAM_URL,
  TWITTER_URL,
  playstoreUrl,
} from "configs";
import { Link } from "react-router-dom";
import "./Footer.scss";

const socialMedia = [
  { url: TWITTER_URL, icon: twitterIcon },
  { url: TELEGRAM_URL, icon: telegramIcon },
  { url: `mailto:${CONTACT_EMAIL}`, icon: mailIcon },
];

const footerlinks = [
  [
    { text: "Explore MITA", path: "/?heading=explore" },
    { text: "Advertise", path: "/?heading=advertise" },
  ],
  [
    { text: "Recharge", path: "/recharge" },
    {
      text: "Upload Music",
      path: "/upload-music",
      comingSoonMessage: "This feature is coming soon.",
    },
  ],
  [
    { text: "Community Guidelines", path: "/community-guidelines" },
    { text: "Terms of Use", path: "/terms-of-use" },
  ],
  [
    { text: "Privacy Policy", path: "/privacy-policy" },
    { text: "Contact Us", path: "/contact-us" },
  ],
  [{ text: "Become P2P merchant", path: "/p2p" }],
];

const Footer = ({ setComingSoonMessage }) => {
  return (
    <div className="footer">
      <div className="download">
        <div>Download now</div>

        <a href={playstoreUrl} target="_blank" rel="noreferrer">
          <img src={downloadOnPlayStore} alt="Get it on Google Play" />
        </a>

        {/* <a href={appstoreUrl} target="_blank" rel="noreferrer"> */}
        <div onClick={() => setComingSoonMessage("Coming soon to Apple Store")}>
          <img src={downloadOnAppStore} alt="Download on the App Store" />
        </div>
      </div>

      <div className="top">
        <div>
          <Link to="/?heading=home">
            <img src={mitaLogoWhiteHorizontal} alt="MITA logo" />
          </Link>
          <div>
            {socialMedia.map((social, i) => (
              <div key={i}>
                <a href={social.url} target="_blank" rel="noreferrer">
                  <img src={social.icon} alt="social media icon" />
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="links">
          {footerlinks.slice(0, footerlinks.length - 1).map((links, i) => (
            <div key={i}>
              {links.map((link, j) => {
                return !link.comingSoonMessage ? (
                  <Link key={j} to={link.path}>
                    {link.text}
                  </Link>
                ) : (
                  <div
                    key={j}
                    onClick={() => setComingSoonMessage(link.comingSoonMessage)}
                    className="link-btn"
                  >
                    {link.text}
                  </div>
                );
              })}
            </div>
          ))}
          {footerlinks[footerlinks.length - 1].map((link, i) => (
            <Link
              key={i}
              to={link.path}
              style={{
                display: "flex",
                padding: "1rem 1.5rem",
                borderRadius: "2.5rem",
                border: "2px solid white",
                alignItems: "center",
                justifyContent: "center",
                flex: "initial",
              }}
            >
              {link.text}
            </Link>
          ))}
        </div>
      </div>

      <div className="bottom">
        <div>&copy; MITA {new Date().getFullYear()}</div>
      </div>
    </div>
  );
};

export default Footer;
